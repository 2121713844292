<template>
<v-container class="sync-configuration-page">
    <div v-if="loading || (currentShop === null)">
        <data-preparation-overlay></data-preparation-overlay>
    </div>
    <div v-else>
        <v-row>
            <span @click="redirectTo('SingleStorePage', { id: currentShopId })"><v-icon>keyboard_arrow_left</v-icon> Back</span>
        </v-row>
        <v-row>
            <v-col cols="12">
                <h1 class="text-h3">
                    <v-icon
                        aria-hidden="false"
                        dark
                        left
                        large
                        color="orange darken-2"
                    >
                    settings
                    </v-icon>
                    Sync Settings
                </h1>
                <div class="title-small-text">
                    <small>Manage how your products sync with your connected {{ partnerStoreLabel }}.</small>
                </div>
                <div v-if="isDirty" class="action-bar">
                    <v-btn large
                        color="primary"
                        class="pl-5 pr-5 float-right pt-1 pb-1"
                        @click="saveConfigurations" 
                    >Save the Changes</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-tabs class="header-tabs"
                    v-model="tab"
                    background-color="transparent"
                >
                    <v-tab
                        v-for="item in items"
                        :key="item.name"
                    >{{ item.name }}</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        v-for="item in items"
                        :key="item.name"
                    >
                        <v-card class="pa-5">
                            <v-row>
                                <v-col cols="12">
                                    <v-card-text>
                                        {{ item.description }}
                                    </v-card-text>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="7" >
                                    <v-list class="pt-0">
                                        <v-list-item
                                            v-for="config in item.configs"
                                            :key="config.label"
                                            class="config-block"
                                            @mouseover="updateConfigDescription(config)"
                                            @mouseout="currentConfigDescription = null"
                                        >
                                            <v-switch
                                                v-model="config.value"
                                                inset
                                                dense
                                                color="blue darken-1"
                                                :label="config.label"
                                            >
                                            </v-switch>
                                            <v-tooltip 
                                                right
                                            >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            color="grey"
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="tooltip-icon"
                                                        >
                                                            error_outline
                                                        </v-icon>
                                                    </template>
                                                    <span>{{ config.tip }}</span>
                                                </v-tooltip>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </div>
</v-container>
</template>
<script>
import { mapState } from 'vuex';
import { LOAD_CURRENT_CONFIGURATIONS, UPDATE_CURRENT_CONFIGURATIONS } from "@/store/actions.type";
import DataPreparationOverlay from "@/views/components/DataPreparationOverlay";

export default {
    name: "ProductSyncConfigurationsPage",
    data: function() {
        return {
            tab: null,
            loading: true,
            currentConfigDescription: null,
            items: [],
            existingConfigsStr: null,
            currentShopId: null,
        };
    },
    created() {
        this.currentConfigDescription = null;
        this.currentShopId = this.$route.params.id;
        this.$store.dispatch(`configs/${LOAD_CURRENT_CONFIGURATIONS}`, {
           shopId: this.currentShopId,
        }).finally(() => {
                this.loading = false;
            });
    },
    components: {
        DataPreparationOverlay,
    },
    computed: {
        ...mapState("configs", [ "currentShop","currentConfigs" ]),
        ...mapState("auth", [ "user" ]),
        partnerStoreLabel() {
            if (this.currentShop != null && this.currentShop.type != "source") {
                return "Source store";
            } else {
                return "Destination store";
            }
        },
        isDirty() {
            if (this.items != null || this.items.length > 0) {
                return this.isDirtyConfig();
            }
            return false;
        }
    },
    watch: {
        currentConfigs(newData) {
            if (newData != null) {
                let data = Object.assign({}, newData);
                this.existingConfigsStr = JSON.stringify(data);
                this.items = this.convertToUxConfigs(data);
            }
        },
    },
    methods: {
        updateConfigDescription(config) {
            this.currentConfigDescription = config.description;
        },
        isDirtyConfig() {
            let isDirty = false;
            if (this.existingConfigsStr != null && this.items != null) {
                let newConfigs = JSON.stringify(this.currentConfigs);
                let oldConfigs = this.existingConfigsStr;
                if (newConfigs != oldConfigs) {
                    isDirty = true;
                }
            }
            return isDirty;
        },
        flattenConfigData(formattedConfigs) {
            let newData = {};
            formattedConfigs.forEach((item) => {
                item.configs.forEach((config) => {
                   newData[config.key] = config.value; // this will become an object! 
                });
            });
            return newData;
        },
        saveConfigurations() {
            this.loading = true;
            this.$store.dispatch(`configs/${UPDATE_CURRENT_CONFIGURATIONS}`, {
                shopId: this.currentShopId,
                newConfigSettings: this.items,
            }).finally(() => {
               this.$store.dispatch(`configs/${LOAD_CURRENT_CONFIGURATIONS}`, {
                    shopId: this.currentShopId,
                });
                this.loading= false;
            });
        },
        convertToUxConfigs(data) {
            const productConfigs = Object.assign({}, {
                name: "PRODUCT SYNC",
                description: "Switch on any product attributes that you would like to sync across all connected source stores.",
                configs: data.product,
            });
            const variantConfigs = Object.assign({}, {
                name: "VARIANT SYNC",
                description: "Switch on any product attributes that you would like to sync across all connected source stores.",
                configs: data.variant,
            });
            const generalConfigs = Object.assign({}, {
                name: "GENERAL",
                description: "Switch on any product attributes that you would like to sync across all connected source stores.",
                configs: data.general,
            });
            return [
                productConfigs,
                variantConfigs,
                generalConfigs,
            ];
        },
        redirectTo(routeName, param) {
            this.$router.push({name: routeName, param: param});
        },
    }
}
</script>
<style lang="scss" scoped>
.sync-configuration-page {
    position: relative;
    .title-small-text {
        padding-left: 57px;
    }
    .header-tabs {
        background-color: transparent;
        .v-tab {
            padding: 10px 80px;
        }
    }
    .config-block {
        border: 1px solid #ccc;
        border-radius: 15px;
        margin: 0px 20px 15px 0px;
        padding: 0px 30px;
        .tooltip-icon {
            position: absolute;
            right: 15px;
        }
    }
    .config-description-helper-content {
        width: 100%;
        height: 100%;
    }
    .action-bar {
        position: absolute;
        overflow: hidden;
        top: 0;
        right: 15px;
    }
}
</style>