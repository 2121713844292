<template>
<div class="data-preparation-overlay-wrapper">
    <v-overlay
        class="text-center"
    >
        <v-card
            light
            class="loading-box"
            width="300px"
        >
          <v-card-title>Loading...</v-card-title>
          <v-card-text>
              <v-img v-if="randomNum == 1" src="@/assets/images/loading/loading-1.gif"></v-img>
              <v-img v-else-if="randomNum == 2" src="@/assets/images/loading/loading-2.gif"></v-img>
              <v-img v-else-if="randomNum == 3" src="@/assets/images/loading/loading-3.gif"></v-img>
              <v-img v-else-if="randomNum == 4" src="@/assets/images/loading/loading-4.gif"></v-img>
              <v-img v-else src="@/assets/images/loading/loading-5.gif"></v-img>
          </v-card-text>
        </v-card>
    </v-overlay>
</div>
</template>
<script>
export default {
  name: "DataPreparationOverlay",
  computed: {
    randomNum() {
      return Math.ceil(Math.random()*5);
    }
  }
}
</script>
<style scoped>
.loading-box {
    padding: 20px;
}
.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
</style>